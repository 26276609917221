import React from 'react'
import Layout from '../../../components/Layout'

import {
  FaHome,
} from 'react-icons/fa';

const SuccessPage = () => {
  return (
    <Layout>
      <section className='hero is-burlywood is-bold'>
        <div className='hero-body'>
          <div className='container'>
            <div className='columns is-vcentered'>

              <div className='column is-12-mobile is-6' >
                <div className='section'>
                  <h1 className='title has-text-weight-semibold is-size-3'>Thank You! We will contact you shortly…</h1>
                </div>
              </div>

              <div className="column is-6 is-flex is-horizontal-center is-hidden-mobile">
                <figure className="image is4by3 mt-2">
                  <FaHome size={340} color={'#f7e151'} />
                </figure>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  )
}

export default SuccessPage
